import * as React from 'react';
import { graphql } from 'gatsby';
import '../global.scss';
import { Helmet } from 'react-helmet';
import Favicon from '../images/favicon.png';

// markup
const IndexPage = (props) => {
	const data = props.data?.datoCmsMerkantic;

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: 'fi',
				}}
			>
				<meta name='description' content={data?.seo?.description} />
				<meta name='og:description' content={data?.seo?.description} />
				<meta name='og:image' content={data?.seo?.image?.url} />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta name='twitter:card' content={data?.seo?.twitterCard} />
				<meta
					name='icon'
					href={data?.favicon?.url ? data?.favicon?.url : Favicon}
				/>

				<link
					rel='shortcut icon'
					type='image/jpg'
					href={data?.favicon?.url ? data?.favicon?.url : Favicon}
				/>
				<title>{data.title}</title>
			</Helmet>
			<header>
				<img className='logo' src={data.logo.url} alt='Merkantics' />
				<hr />
			</header>
			<main>
				<div className='columns'>
					<div
						className='content content-1'
						dangerouslySetInnerHTML={{
							__html: data.content1Node.childMarkdownRemark.html,
						}}
					></div>

					<div
						className='content content-2'
						dangerouslySetInnerHTML={{
							__html: data.content2Node.childMarkdownRemark.html,
						}}
					></div>
				</div>
				<div className='infograph'>
					{data.infograph && (
						<img
							src={data.infograph.url}
							alt={data?.infograph?.alt ? data?.infograph?.alt : ''}
						/>
					)}
				</div>

				<div
					className='content content-3'
					dangerouslySetInnerHTML={{
						__html: data.content3Node.childMarkdownRemark.html,
					}}
				></div>
				<div className='links'>
					{data.links.map((link, i) => (
						<div key={i}>
							{link.image && <img src={link.image?.url} alt={link.title} />}
							<a href={link.url ? link.url : '/'}>{link.title}</a>
						</div>
					))}
				</div>
				<div className='columns'>
					<div
						className='content content-4'
						dangerouslySetInnerHTML={{
							__html: data.content4Node.childMarkdownRemark.html,
						}}
					></div>
					<div className='image'>
						{data.image && <img src={data.image.url} alt={data?.image?.alt} />}
					</div>
				</div>
				<div className='contact'>
					{data.contact.map((contact, i) => (
						<div key={i}>
							<h3>{contact.name}</h3>
							<span>{contact.subtitle}</span>
							<a href={`mailto: ${contact.email}`}> {contact.email}</a>
							<a href={`tel:${contact.telephone}`}>{contact.telephone}</a>
						</div>
					))}
				</div>
			</main>
			<footer>
				<img className='logo' src={data.logo.url} alt='Merkantics' />
				<p>Merkantics {new Date().getFullYear()}</p>
			</footer>
		</>
	);
};

export default IndexPage;

export const query = graphql`
	query Merkantics {
		datoCmsMerkantic {
			id
			title
			logo {
				url
				fixed(height: 200) {
					src
				}
			}
			seo {
				title
				description
				twitterCard
			}
			content1Node {
				childMarkdownRemark {
					html
				}
			}
			content2Node {
				childMarkdownRemark {
					html
				}
			}
			content3Node {
				childMarkdownRemark {
					html
				}
			}
			content4Node {
				childMarkdownRemark {
					html
				}
			}
			image {
				url
				alt
			}
			infograph {
				url
				alt
			}
			contact {
				name
				subtitle
				email
				telephone
			}
			links {
				url
				title
				image {
					url
				}
			}
			favicon {
				url
			}
		}
	}
`;
